export default [
  {
    path: '/prices',
    name: 'prices',
    component: () => import('@/views/prices/Prices.vue'),
    meta: {
      resource: 'Prices',
      action: 'read',
    },
  },
  {
    path: '/prices/newPrices',
    name: 'add-prices',
    component: () => import('@/views/prices/add-prices/PricesAdd.vue'),
    meta: {
      resource: 'Prices',
      action: 'read',
    },
  },
  {
    path: '/prices/editPrices/:id',
    name: 'edit-prices',
    component: () => import('@/views/prices/edit-prices/PricesEdit.vue'),
    meta: {
      resource: 'Prices',
      action: 'read',
    },
  },
  {
    path: '/purchase-price-lists',
    name: 'purchase-price-lists',
    component: () => import('@/views/prices/PurchasePriceLists.vue'),
    // meta: {
    //   resource: 'PublicPrices',
    //   action: 'read',
    // },
  },
  {
    path: '/sell-price-lists',
    name: 'sell-price-lists',
    component: () => import('@/views/prices/SellPriceLists.vue'),
    // meta: {
    //   resource: 'PublicPrices',
    //   action: 'read',
    // },
  },
  {
    path: '/company-products',
    name: 'company-products',
    component: () => import('@/views/company-products/CompanyProducts.vue'),
    // meta: {
    //   resource: 'PublicPrices',
    //   action: 'read',
    // },
  },
  {
    path: '/view-expenses-and-incomes',
    name: 'view-expenses-and-incomes',
    component: () => import('@/views/manager/view-expenses-and-incomes/view-expenses-and-incomes.vue'),
    // meta: {
    //     resource: 'Tickets',
    //     action: 'read',
    // },
  }
]
