export const initialAbility = [
  {

  }
  // {
  //   action: 'read',
  //   subject: 'Auth',
  // },
  // {
  //   action: 'read',
  //   subject: 'Landing',
  // },
]

export const _ = undefined
