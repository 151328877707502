import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import * as VueGoogleMaps from "vue2-google-maps";

import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-components';
import {
  applyPolyfills,
  defineCustomElements
} from '@aws-amplify/ui-components/loader';
import amplifyconfig from './amplifyconfiguration.json';

import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import BigNumber from "bignumber.js";
// import cors from "cors";

// Global Components
import "./global-components";

// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";

// Axios Mock Adapter
import "@/@fake-db/db";

// Firebase
// import { auth, app } from "./firebase"
// // console.log(auth)

// app.auth().onAuthStateChanged(user => {
//   console.log("fireabse")
//   store.dispatch("user/fetchUser", user);
// });

Amplify.configure(amplifyconfig);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/amplify-\w*/];

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// Maps API
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDEASY9qPEcJorcbGSI2JfwZa8htKMGn44",
    libraries: "places",
  },
});

Vue.prototype.$services = "https://8gvygbs670.execute-api.us-east-1.amazonaws.com/";
// Vue.prototype.$services = "http://localhost:3000/";
Vue.prototype.$axios = axios;
Vue.use(VueAxios, axios);
// axios.defaults.baseURL = 'URL'
axios.defaults.headers.common["x-access-token"] = localStorage.getItem("token");
axios.defaults.headers.common["x-refresh-token"] = localStorage.getItem(
  "refreshToken"
);

Vue.use(require("vue-moment"));
Vue.use(require("moment-timezone"));

import moment from "vue-moment";
import moment_tz from "moment-timezone";
Vue.prototype.moment = moment;
Vue.prototype.moment_tz = moment_tz;

import { verifyToken, refreshToken } from "@/auth/utils";

Vue.prototype.$verifyToken = verifyToken;
Vue.prototype.$refreshToken = refreshToken;

Vue.prototype.$BigNumber = BigNumber;
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
