export default [
  {
    path:'/calculator',
    name: 'calculator',
    component: () => import('@/views/calculator/calculator.vue'),
    meta: {
      resource: 'Calculator',
      action: 'read',
    },
  },
  // {
  //   path: '/dashboard/ecommerce',
  //   name: 'dashboard-ecommerce',
  //   component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  // },
]
