export default [
  {
    path:'/scale-snapshots',
    name: 'scaleSnapshots',
    component: () => import('@/views/snapshots/ScaleSnapshots.vue'),
    meta: {
      resource: 'SnapshotsWeighing',
      action: 'read',
    },
  },
]
