export default [
    {
      path:'/scale',
      name: 'scale',
      component: () => import('@/views/scale/Scale.vue'),
      meta: {
        resource: 'Scale',
        action: 'read',
        layout: 'full',
      },
    },
  {
    path:'/scaleCustomer',
    name: 'scaleCustomer',
    component: () => import('@/views/scaleCustomer/scaleCustomer.vue'),
    meta: {
      resource: 'Scale',
      action: 'read',
      layout: 'full',
    },
  },
  ]
