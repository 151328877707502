export default [
  {
    path: '/clients',
    name: 'clients',
    component: () => import('@/views/clients/Clients.vue'),
    meta: {
      resource: 'Clients',
      action: 'read',
    },
  },
  {
    path: '/clients/add-client',
    name: 'add-client',
    component: () => import('@/views/clients/add-client/AddClient.vue'),
    meta: {
      resource: 'Clients',
      action: 'read',
    },
  },
  {
    path: '/clients/edit-client/:id',
    name: 'edit-client',
    component: () => import('@/views/clients/edit-client/EditClient.vue'),
    meta: {
      resource: 'Clients',
      action: 'read',
    },
  },
  {
    path: '/providers',
    name: 'providers',
    component: () => import('@/views/providers/Providers.vue'),
  },
  {
    path: '/company-providers',
    name: 'company-providers',
    component: () => import('@/views/providers/company-providers.vue'),
  },
  {
    path: '/seller-company-providers',
    name: 'seller-company-providers',
    component: () => import('@/views/providers/seller-company-providers.vue'),
  },
  {
    path: '/providers/add-provider',
    name: 'add-provider',
    component: () => import('@/views/providers/add-provider/AddProvider.vue'),
    meta: {
      resource: 'Clients',
      action: 'read',
    },
  },
  {
    path: '/providers/edit-provider/:id',
    name: 'edit-provider',
    component: () => import('@/views/providers/edit-provider/EditProvider.vue'),
    meta: {
      resource: 'Clients',
      action: 'read',
    },
  },
]
