export default [
    {
      path: '/export-import',
      name: 'exportImport',
      component: () => import('@/views/exportImport/ExportImport.vue'),
      meta: {
        resource: 'ExportImport',
        action: 'read',
      },
    }
  ]
