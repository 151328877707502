export default [
  {
    path: '/logisticExpense',
    name: 'logisticExpense',
    component: () => import('@/views/logistic/expense/Expense.vue'),
    meta: {
      resource: 'Logistic',
      action: 'read',
    },
  },
  {
    path: '/logisticRoute',
    name: 'logisticRoute',
    component: () => import('@/views/logistic/route/Route.vue'),
    meta: {
      resource: 'Logistic',
      action: 'read',
    },
  },
  {
    path: '/logisticRoute/add-route',
    name: 'add-logisticRoute',
    component: () => import('@/views/logistic/route/add-route/AddRoute.vue'),
    meta: {
      resource: 'Logistic',
      action: 'read',
    },
  },
  {
    path: '/logisticRoute/history/:id',
    name: 'edit-logisticRoute',
    component: () => import('@/views/logistic/route/edit-route/EditRoute.vue'),
    meta: {
      resource: 'Logistic',
      action: 'read',
    },
  },
]
