export default [
  {
    path: '/landing',
    name: 'landing',
    component: () => import('@/views/landing/Landing.vue'),
    meta: {
      layout: 'full',
    },
  },
]
