export default [
    {
      path:'/verifyTicket',
      name: 'verifyTicket',
      component: () => import('@/views/verifyTicket/verifyTicket.vue'),
      meta: {
          role: 'CASHIER',
          action: 'read',
          layout: 'full',
      },
    },
  ]
