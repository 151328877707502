export default [
  {
    path: '/vehicles/liveLocation',
    name: 'liveLocation',
    component: () => import('@/views/gps/GPS.vue'),
    meta: {
      resource: 'Gps',
      action: 'read',
    }
  },
  {
    path: '/vehicles/historyLocations',
    name: 'historyLocations',
    component: () => import('@/views/gps/historyLocations/HistoryLocations.vue'),
    meta: {
      resource: 'Gps',
      action: 'read',
    }
  }
]
