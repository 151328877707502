import Vue from 'vue'
import VueRouter from 'vue-router'
import { Auth } from 'aws-amplify';

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import store from '@/store'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import landing from './routes/landing'
import home from './routes/home'
import calculator from './routes/calculator'
import scale from './routes/scale'
import balances from './routes/balances'
import purchaseOrders from './routes/purchaseOrders'
import sellOrders from './routes/sellOrders'
import inventory from './routes/inventory'
import clientMetalExpense from './routes/clientMetalExpense'
import dailyCut from './routes/dailyCut'
import snapshots from './routes/snapshots'
import prices from './routes/prices'
import clients from './routes/clients'
import devices from './routes/devices'
import logistic from './routes/logistic'
import vehicles from './routes/vehicles'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import exportImport from './routes/exportImport'
import verifyTicket from './routes/verifyTicket'
import cashier from './routes/cashier';
// import { auth } from '@/firebase'
// import { auth, app } from "../firebase"


Vue.use(VueRouter)
const userData = getUserData()

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: userData ? userData.homepage : 'home' } },
    // { path: '/', redirect: { name: 'landing' } },
    // ...apps,
    // ...dashboard,
    ...pages,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
    ...balances,
    ...landing,
    ...home,
    ...vehicles,
    ...calculator,
    ...scale,
    ...purchaseOrders,
    ...sellOrders,
    ...prices,
    ...inventory,
    ...clients,
    ...logistic,
    ...clientMetalExpense,
    ...dailyCut,
    ...snapshots,
    ...exportImport,
    ...devices,
    ...verifyTicket,
    ...cashier,
    {
      path: '/*',
      redirect: '/error-404',
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})

router.beforeEach((to, _, next) => {
  if (to.name === 'landing') return next()
  
  Auth.currentAuthenticatedUser().then(user => {
    // BUG
    // if (to.meta.role !== userData.role)
    //       return next({ name: 'error-404' })

  }).catch(error=> {
    console.log(`to.name`, to.name)
    console.log(`error`, error)
    if (to.name !== 'auth-login') {
      return next({ name: 'auth-login' })
    }
  })

  //   if (!isLoggedIn) {
  //       return next({ name: 'providers' })
  //   }
  //   return next({ name: 'misc-not-authorized' })

  // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null, userData ? userData.homepage : 'home'))
  // }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
