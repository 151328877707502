export default [
    {
      path:'/cashier-balances',
      name: 'cashier-balances',
      component: () => import('@/views/cashiers/cashier-balances/cashierBalances.vue'),
      // meta: {
      //     resource: 'CashierBalance',
      //     action: 'read',
      // },
    },
    {
        path:'/cashier-balance-reports',
        name: 'cashier-balance-reports',
        component: () => import('@/views/cashiers/cashier-balance-reports/cashierBalanceReports.vue'),
        // meta: {
        //     resource: 'CashierBalance',
        //     action: 'read',
        // },
    },
    {
        path:'/view-cashier-balance-report/:cashierBalanceReportId',
        name: 'view-cashier-balance-report',
        component: () => import('@/views/cashiers/view-cashier-balance-report/view-cashier-balance-report.vue'),
        // meta: {
        //     resource: 'CashierBalance',
        //     action: 'read',
        // },
    },
    {
        path: '/view-purchase-tickets',
        name: 'view-purchase-tickets',
        component: () => import('@/views/manager/view-purchase-tickets/view-purchase-tickets.vue'),
        // meta: {
        //     resource: 'Tickets',
        //     action: 'read',
        // },
    }
  ]
