export default [
    {
      path: '/sell-orders',
      name: 'sell-orders',
      component: () => import('@/views/sellOrders/sell-orders.vue'),
      meta: {
        resource: 'Orders',
        action: 'read',
      },
    },
    {
      path: '/sellOrders/activeOrders',
      name: 'active-sell-orders',
      component: () => import('@/views/sellOrders/activeOrders/activeOrders.vue'),
      meta: {
        resource: 'Orders',
        action: 'read',
      },
    },
    {
      path: '/sell-orders/create-sell-order',
      name: 'create-sell-order',
      component: () => import('@/views/sellOrders/create-sell-order/create-sell-order.vue'),
      meta: {
        resource: 'Orders',
        action: 'read',
      },
    },
    {
      path: '/sell-orders/edit-sell-order/:sellOrderId',
      name: 'edit-sell-order',
      component: () => import('@/views/sellOrders/edit-sell-order/edit-sell-order.vue'),
      meta: {
        resource: 'Orders',
        action: 'read',
      },
    },
    {
      path: '/sellOrders/preview/:id',
      name: 'preview-sellOrders',
      component: () => import('@/views/sellOrders/order-preview/OrderPreview.vue'),
      meta: {
        resource: 'Orders',
        action: 'read',
      },
    },
  ]
