export default [
  {
    path: '/expenses',
    name: 'expenses',
    component: () => import('@/views/clientMetalExpense/expenses/Expenses.vue'),
    meta: {
      resource: 'Expenses',
      action: 'read',
    },
  },
  {
    path: '/commissions',
    name: 'commissions',
    component: () => import('@/views/clientMetalExpense/commissions/Commissions.vue'),
    meta: {
      resource: 'Expenses',
      action: 'read',
    },
  },
  {
    path: '/commissions/add-commission',
    name: 'add-commission',
    component: () => import('@/views/clientMetalExpense/commissions/add-commission/AddCommission.vue'),
    meta: {
      resource: 'Expenses',
      action: 'read',
    },
  },
  {
    path: '/commissions/history/:id',
    name: 'edit-commission',
    component: () => import('@/views/clientMetalExpense/commissions/edit-commission/EditCommission.vue'),
    meta: {
      resource: 'Expenses',
      action: 'read',
    },
  },
]
