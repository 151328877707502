import { $themeBreakpoints } from '@themeConfig'
import { DropdownPlugin } from 'bootstrap-vue';
// import { auth } from "../../firebase.js"

export default {
  namespaced: true,
  state: {
    user: {
      loggedIn: false,
      data: null
    }
  },
  getters: {
    user(state){
      return state.user
    }
  },
  mutations: {

  },  
  actions: {
  },
}
