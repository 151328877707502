export default [
  {
    path: '/purchaseOrders',
    name: 'purchase-orders',
    component: () => import('@/views/purchaseOrders/PurchaseOrders.vue'),
    meta: {
      resource: 'Orders',
      action: 'read',
    },
  },
  {
    path: '/purchaseOrders/activeOrders',
    name: 'active-purchase-orders',
    component: () => import('@/views/purchaseOrders/activeOrders/activeOrders.vue'),
    meta: {
      resource: 'Orders',
      action: 'read',
    },
  },
  {
    path: '/purchaseOrders/new',
    name: 'add-purchaseOrders',
    component: () => import('@/views/purchaseOrders/add-order/AddPurchaseOrders.vue'),
    meta: {
      resource: 'Orders',
      action: 'read',
    },
  },
  {
    path: '/purchaseOrders/edit/:id',
    name: 'edit-purchaseOrders',
    component: () => import('@/views/purchaseOrders/edit-order/EditPurchaseOrders.vue'),
    meta: {
      resource: 'Orders',
      action: 'read',
    },
  },
  {
    path: '/purchaseOrders/preview/:id',
    name: 'preview-purchaseOrders',
    component: () => import('@/views/purchaseOrders/order-preview/OrderPreview.vue'),
    meta: {
      resource: 'Orders',
      action: 'read',
    },
  },
  {
    path: '/purchaseOrdersAdjustment/:id',
    name: 'add-purchaseOrdersAdjustment',
    component: () => import('@/views/purchaseOrders/add-orderAdjustment/AddPurchaseOrders.vue'),
    meta: {
      resource: 'Orders',
      action: 'read',
    },
  },
  {
    path: '/purchaseTickets',
    name: 'purchase-tickets',
    component: () => import('@/views/purchaseOrders/PurchaseTickets.vue'),
    meta: {
      resource: 'Tickets',
      action: 'read',
    },
  }
]
