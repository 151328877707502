export default [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/Home.vue'),
    // meta: {
    //   resource: 'Home',
    //   action: 'read',
    // }
  }
]
