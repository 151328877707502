export default [
  {
    path: '/accountsPayable',
    name: 'accountsPayable',
    component: () => import('@/views/companiesBalance/accountsPayable/accountsPayable.vue'),
    meta: {
      resource: 'Balances',
      action: 'read',
    },
  },
  {
    path: '/accountsReceivable',
    name: 'accountsReceivable',
    component: () => import('@/views/companiesBalance/accountsReceivable/accountsReceivable.vue'),
    meta: {
      resource: 'Balances',
      action: 'read',
    },
  },
  {
    path: '/commissionistsBalance',
    name: 'commissionistsBalances',
    component: () => import('@/views/commissionistsBalance/CommissionistsBalance.vue'),
    meta: {
      resource: 'Balances',
      action: 'read',
    },
  },
  {
    path: '/paymentBills/:id',
    name: 'paymentBills',
    component: () => import('@/views/paymentBills/paymentBills.vue'),
    meta: {
      resource: 'Balances',
      action: 'read',
    },
  },
  {
    path: '/adjustmentBalance',
    name: 'adjustmentBalance',
    component: () => import('@/views/companiesBalance/adjustmentBalance/adjustmentBalance.vue'),
    meta: {
      resource: 'Balances',
      action: 'read',
    },
  },
]
