export default [
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import('@/views/inventory/Inventory.vue'),
    meta: {
      resource: 'Inventory',
      action: 'read',
    },
  },
  // {
  //   path: '/inventory/history/:id',
  //   name: 'edit-inventory',
  //   component: () => import('@/views/inventory/edit-inventory/EditInventory.vue'),
  //   meta: {
  //     resource: 'Inventory',
  //     action: 'read',
  //   },
  // },
]
