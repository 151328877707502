export default [
    {
      path: '/device-monitoring',
      name: 'deviceMonitoring',
      component: () => import('@/views/deviceMonitoring/DeviceMonitoring.vue'),
      meta: {
        resource: 'Devices',  // FIXME
        action: 'read',
      },
    }
  ]
